import { useStoreActions, useStoreState } from '@/common/providers/easy-peasy/hooks';

function usePageSettings() {
  var mobileMenuOpen = useStoreState(function (state) {
    return state.pageSettings.mobileMenuOpen;
  });
  var disableStickyHeader = useStoreActions(function (actions) {
    return actions.pageSettings.disableStickyHeader;
  });
  var stickHeaderEnabled = useStoreState(function (state) {
    return state.pageSettings.stickHeaderEnabled;
  });
  return {
    disableStickyHeader: disableStickyHeader,
    stickHeaderEnabled: stickHeaderEnabled,
    mobileMenuOpen: mobileMenuOpen
  };
}

export { usePageSettings };