import _uniqBy from "lodash-es/uniqBy";
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import Helmet from 'react-helmet';
import { useAppInfo } from '@/common/hooks';
import { jsx as ___EmotionJSX } from "@emotion/core";

var SEO = function SEO(_ref) {
  var description = _ref.description,
      lang = _ref.lang,
      _ref$meta = _ref.meta,
      meta = _ref$meta === void 0 ? [] : _ref$meta,
      title = _ref.title;

  var _useAppInfo = useAppInfo(),
      defaultMetaDescription = _useAppInfo.defaultMetaDescription,
      defaultMetaTitle = _useAppInfo.defaultMetaTitle;

  var _useIntl = useIntl(),
      locale = _useIntl.locale;

  var htmlLang = lang || locale;
  var metaDescription = description || defaultMetaDescription;
  var defaultMeta = [{
    name: 'description',
    content: metaDescription
  }, {
    property: 'og:title',
    content: title
  }, {
    property: 'og:description',
    content: metaDescription
  }, {
    property: 'og:type',
    content: 'website'
  }, {
    name: 'twitter:card',
    content: 'summary_large_image'
  }, {
    name: 'twitter:creator',
    content: '@ElementumArt'
  }, {
    name: 'twitter:title',
    content: title
  }, {
    name: 'twitter:description',
    content: metaDescription
  }, {
    name: 'twitter:site',
    content: '@ElementumArt'
  }, {
    name: 'robots',
    content: CONFIG.seo.defaultRobots
  }];
  return ___EmotionJSX(Helmet, {
    htmlAttributes: {
      lang: htmlLang
    },
    title: title,
    titleTemplate: "%s | " + defaultMetaTitle,
    meta: _uniqBy(meta.concat(defaultMeta), function (data) {
      return data.name || data.property;
    })
  });
};

SEO.displayName = "SEO";
export { SEO };