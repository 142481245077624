var IconType;

(function (IconType) {
  IconType["Instagram"] = "INSTAGRAM";
  IconType["Facebook"] = "FACEBOOK";
  IconType["Youtube"] = "YOUTUBE";
  IconType["Twitter"] = "TWITTER";
  IconType["Telegram"] = "TELEGRAM";
})(IconType || (IconType = {}));

export { IconType };