var LinkType;

(function (LinkType) {
  LinkType["Entry"] = "ENTRY";
  LinkType["Url"] = "URL";
  LinkType["Custom"] = "CUSTOM";
  LinkType["Asset"] = "ASSET";
  LinkType["Category"] = "CATEGORY";
  LinkType["Email"] = "EMAIL";
  LinkType["Site"] = "SITE";
  LinkType["Tel"] = "TEL";
  LinkType["User"] = "USER";
})(LinkType || (LinkType = {}));

var LinkStyles;

(function (LinkStyles) {
  LinkStyles["Link"] = "LINK";
  LinkStyles["Button"] = "BUTTON";
})(LinkStyles || (LinkStyles = {}));

export { LinkType, LinkStyles };