import { useTranslation } from '@/common/hooks/translation-hook'; // eslint-disable-next-line import/exports-last,import/group-exports

function useAppInfo() {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  return {
    // eslint-disable-next-line id-blacklist
    appName: t('meta.appName'),
    defaultMetaTitle: t('meta.title'),
    defaultMetaDescription: t('meta.description')
  };
} // eslint-disable-next-line import/group-exports


export { useAppInfo };