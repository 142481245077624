import _styled from "@emotion/styled-base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import React from 'react';
import { jsx as ___EmotionJSX } from "@emotion/core";

var Base = _styled("div", {
  target: "e9bh0ak0",
  label: "request-success-notification_Base"
})(process.env.NODE_ENV === "production" ? {
  name: "1cr93fp",
  styles: "hyphens:auto;word-break:break-word;"
} : {
  name: "1cr93fp",
  styles: "hyphens:auto;word-break:break-word;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL25vdGlmaWNhdGlvbi1iYW5uZXIvcmVxdWVzdC1zdWNjZXNzLW5vdGlmaWNhdGlvbi50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBR3VCIiwiZmlsZSI6Ii9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL25vdGlmaWNhdGlvbi1iYW5uZXIvcmVxdWVzdC1zdWNjZXNzLW5vdGlmaWNhdGlvbi50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCc7XG5pbXBvcnQgUmVhY3QsIHsgQ1NTUHJvcGVydGllcyB9IGZyb20gJ3JlYWN0JztcblxuY29uc3QgQmFzZSA9IHN0eWxlZC5kaXZgXG4gIGh5cGhlbnM6IGF1dG87XG4gIHdvcmQtYnJlYWs6IGJyZWFrLXdvcmQ7XG5gO1xuXG5leHBvcnQgaW50ZXJmYWNlIFJlcXVlc3RTdWNjZXNzTm90aWZpY2F0aW9uUHJvcHMge1xuICBtZXNzYWdlOiBzdHJpbmc7XG4gIHN0eWxlPzogQ1NTUHJvcGVydGllcztcbiAgY2xhc3NOYW1lPzogc3RyaW5nO1xufVxuXG5jb25zdCBSZXF1ZXN0U3VjY2Vzc05vdGlmaWNhdGlvbjogUmVhY3QuRkM8UmVxdWVzdFN1Y2Nlc3NOb3RpZmljYXRpb25Qcm9wcz4gPSAoe1xuICBtZXNzYWdlLFxuICBzdHlsZSxcbiAgY2xhc3NOYW1lLFxufSkgPT4ge1xuICByZXR1cm4gKFxuICAgIDxCYXNlIHN0eWxlPXtzdHlsZX0gY2xhc3NOYW1lPXtjbGFzc05hbWV9PlxuICAgICAge21lc3NhZ2V9XG4gICAgPC9CYXNlPlxuICApO1xufTtcblxuZXhwb3J0IHsgUmVxdWVzdFN1Y2Nlc3NOb3RpZmljYXRpb24gfTtcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

var RequestSuccessNotification = function RequestSuccessNotification(_ref) {
  var message = _ref.message,
      style = _ref.style,
      className = _ref.className;
  return ___EmotionJSX(Base, {
    style: style,
    className: className
  }, message);
};

RequestSuccessNotification.displayName = "RequestSuccessNotification";
export { RequestSuccessNotification };