import _filter from "lodash-es/filter";
import { DisplayConditionType } from '@/common/utils/cms-types/display-condition-types';

var shouldDisplay = function shouldDisplay(condition, isAuthenticated) {
  switch (condition) {
    case DisplayConditionType.Always:
      return true;

    case DisplayConditionType.LoggedIn:
      return isAuthenticated;

    case DisplayConditionType.Unauthenticated:
      return !isAuthenticated;

    default:
      return true;
  }
};

var filterLinks = function filterLinks(links, isAuthenticated) {
  if (!links) {
    return [];
  }

  return _filter(links, function (navEntry) {
    return navEntry && shouldDisplay(navEntry.displayCondition, isAuthenticated);
  });
};

export { shouldDisplay, filterLinks };