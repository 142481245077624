import { useStoreActions, useStoreState } from '@/common/providers/easy-peasy/hooks';

function useNotifications() {
  var queuedMessages = useStoreState(function (state) {
    return state.notifications.queuedMessagesCount;
  });
  var current = useStoreState(function (state) {
    return state.notifications.current;
  });
  var show = useStoreActions(function (actions) {
    return actions.notifications.show;
  });
  var dismissCurrent = useStoreActions(function (actions) {
    return actions.notifications.dismissCurrent;
  });
  return {
    show: show,
    dismissCurrent: dismissCurrent,
    current: current,
    queuedMessages: queuedMessages
  };
}

export { useNotifications };