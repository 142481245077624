import _debounce from "lodash-es/debounce";
import { useEffect, useState } from 'react';
import { motionValue } from 'framer-motion';
import useIsomorphicLayoutEffect from "react-use/esm/useIsomorphicLayoutEffect";

function createScrollMotionValues() {
  return {
    scrollX: motionValue(0),
    scrollY: motionValue(0)
  };
}

var viewportScrollValues = createScrollMotionValues();

function createScrollUpdater(values, getOffsets) {
  var raf = null;

  var update = function update() {
    if (raf) {
      cancelAnimationFrame(raf);
    }

    raf = requestAnimationFrame(function () {
      var _getOffsets = getOffsets(),
          xOffset = _getOffsets.xOffset,
          yOffset = _getOffsets.yOffset; // Set absolute positions


      if (values.scrollX.get() !== xOffset) {
        values.scrollX.set(xOffset);
      }

      if (values.scrollY.get() !== yOffset) {
        values.scrollY.set(yOffset);
      }
    });
  };

  update();
  return _debounce(update, 1000 / 40, {
    maxWait: 1000 / 25
  });
}

function getViewportScrollOffsets() {
  var el = document.scrollingElement || document.documentElement;
  return {
    xOffset: Math.round(el.scrollLeft),
    yOffset: Math.round(el.scrollTop)
  };
}

var hasListeners = false;

function addEventListeners() {
  hasListeners = true;
  if (typeof window === 'undefined') return;
  var updateScrollValues = createScrollUpdater(viewportScrollValues, getViewportScrollOffsets);
  window.addEventListener('scroll', updateScrollValues, {
    passive: true,
    capture: false
  });
  /* const onRaf = () => {
    updateScrollValues();
     requestAnimationFrame(onRaf);
  };
   requestAnimationFrame(onRaf);
  */
}
/**
 * Returns MotionValues that update when the viewport scrolls:
 *
 * - `scrollX` — Horizontal scroll distance in pixels.
 * - `scrollY` — Vertical scroll distance in pixels.
 * - `scrollXProgress` — Horizontal scroll progress between `0` and `1`.
 * - `scrollYProgress` — Vertical scroll progress between `0` and `1`.
 *
 * @library
 *
 * ```jsx
 * import * as React from "react"
 * import {
 *   Frame,
 *   useViewportScroll,
 *   useTransform
 * } from "framer"
 *
 * export function MyComponent() {
 *   const { scrollYProgress } = useViewportScroll()
 *   return <Frame scaleX={scrollYProgress} />
 * }
 * ```
 *
 * @motion
 *
 * ```jsx
 * export const MyComponent = () => {
 *   const { scrollYProgress } = useViewportScroll()
 *   return <motion.div style={{ scaleX: scrollYProgress }} />
 * }
 * ```
 *
 * @public
 */


function useRafViewportScroll() {
  useIsomorphicLayoutEffect(function () {
    !hasListeners && addEventListeners();
  }, []);
  return viewportScrollValues;
}

function useScrollTop(fps) {
  if (fps === void 0) {
    fps = 30;
  }

  var _useState = useState(0),
      scrollTop = _useState[0],
      setScrollTop = _useState[1];

  var _useRafViewportScroll = useRafViewportScroll(),
      scrollY = _useRafViewportScroll.scrollY;

  useEffect(function () {
    var update = _debounce(function () {
      setScrollTop(scrollY.get());
    }, 1000 / fps, {
      maxWait: 1000 / (fps - 1),
      leading: true,
      trailing: true
    });

    var unsubscribe = scrollY.onChange(update);
    return function () {
      unsubscribe();
    };
  }, [fps, scrollY]);
  return scrollTop;
}

export { useRafViewportScroll, useScrollTop };