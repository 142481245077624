import { useMemo } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

function useTranslation() {
  var intl = useIntl();
  var t = useMemo(function () {
    return function (messageId, values) {
      return intl.formatMessage({
        id: messageId
      }, values);
    };
  }, [intl]);
  return {
    t: t
  };
}

export { useTranslation };