/* eslint-disable unicorn/filename-case */
import { useEffect } from 'react';

function useAppResize() {
  useEffect(function () {
    if ('-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style) {
      document.body.classList.add('ie11');
    }

    var isInWebAppiOS = window.navigator.standalone === true;
    var isInWebAppChrome = typeof window !== 'undefined' ? window.matchMedia('(display-mode: standalone)').matches : false;

    if (isInWebAppiOS || isInWebAppChrome) {
      document.body.classList.add('standalone');
    }

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize, false);
    return function () {
      window.removeEventListener('resize', handleWindowResize, false);
    };
  }, []);

  var handleWindowResize = function handleWindowResize() {
    var vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + "px");
  };
}

export { useAppResize };