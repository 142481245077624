import _map from "lodash-es/map";
import _isNil from "lodash-es/isNil";
import _identity from "lodash-es/identity";
import _filter from "lodash-es/filter";

function isNotEmpty(value) {
  return !_isNil(value);
}

var mapMaybe = function mapMaybe(collection, iteratee) {
  if (iteratee === void 0) {
    iteratee = _identity;
  }

  var filtered = _filter(collection, isNotEmpty);

  return _filter(_map(filtered, iteratee), isNotEmpty);
};

export { mapMaybe };