import _styled from "@emotion/styled-base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var UnstyledButton = _styled("button", {
  target: "e1i49hmk0",
  label: "unstyled-button_UnstyledButton"
})(process.env.NODE_ENV === "production" ? {
  name: "1sicd3e",
  styles: "appearance:none;background-color:transparent;border:none;box-shadow:none;color:inherit;outline:none;&:focus{box-shadow:none;outline:none;}"
} : {
  name: "1sicd3e",
  styles: "appearance:none;background-color:transparent;border:none;box-shadow:none;color:inherit;outline:none;&:focus{box-shadow:none;outline:none;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL2J1dHRvbi91bnN0eWxlZC1idXR0b24udHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVvQyIsImZpbGUiOiIvYnVpbGRzL21vb25hL21vb25hLWZyb250ZW5kL3NyYy9jb21tb24vY29tcG9uZW50cy9idXR0b24vdW5zdHlsZWQtYnV0dG9uLnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJztcblxuY29uc3QgVW5zdHlsZWRCdXR0b24gPSBzdHlsZWQuYnV0dG9uYFxuICBhcHBlYXJhbmNlOiBub25lO1xuICBiYWNrZ3JvdW5kLWNvbG9yOiB0cmFuc3BhcmVudDtcbiAgYm9yZGVyOiBub25lO1xuICBib3gtc2hhZG93OiBub25lO1xuICBjb2xvcjogaW5oZXJpdDtcbiAgb3V0bGluZTogbm9uZTtcblxuICAmOmZvY3VzIHtcbiAgICBib3gtc2hhZG93OiBub25lO1xuICAgIG91dGxpbmU6IG5vbmU7XG4gIH1cbmA7XG5cbmV4cG9ydCB7IFVuc3R5bGVkQnV0dG9uIH07XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

export { UnstyledButton };