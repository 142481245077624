import { useCallback, useState } from 'react';
import useMount from "react-use/esm/useMount";
import { useAuth } from '@/common/hooks/auth-hook';
import { useStoreActions, useStoreState } from '@/common/providers/easy-peasy/hooks';

function useProfile() {
  var _useAuth = useAuth(),
      isLoggedIn = _useAuth.isLoggedIn,
      authLogout = _useAuth.logout,
      accessToken = _useAuth.accessToken,
      isAuthLoading = _useAuth.isLoading;

  var ensureProfileLoaded = useStoreActions(function (actions) {
    return actions.profile.ensureProfileLoaded;
  });
  var updateProfileForm = useStoreActions(function (actions) {
    return actions.profile.updateProfileForm;
  });
  var saveProfile = useStoreActions(function (actions) {
    return actions.profile.saveProfile;
  });
  var uploadProfileAvatar = useStoreActions(function (actions) {
    return actions.profile.uploadProfileAvatar;
  });
  var resendEmailVerification = useStoreActions(function (actions) {
    return actions.profile.resendEmailVerification;
  });
  var profile = useStoreState(function (state) {
    return state.profile.profile;
  });
  var isProfileCompleted = useStoreState(function (state) {
    return state.profile.isProfileCompleted;
  });
  var auth0UserFetched = useStoreState(function (state) {
    return state.profile.auth0UserFetched;
  });
  var isFullyActivated = useStoreState(function (state) {
    return state.profile.isFullyActivated;
  });
  var canPurchaseArtwork = useStoreState(function (state) {
    return state.profile.canPurchaseArtwork;
  });
  var isEmailVerified = useStoreState(function (state) {
    return state.profile.isEmailVerified;
  });
  var emailVerificationRequired = useStoreState(function (state) {
    return state.profile.emailVerificationRequired;
  });
  var canResendEmailVerification = useStoreState(function (state) {
    return state.profile.canResendEmailVerification;
  });
  var profileLoading = useStoreState(function (state) {
    return state.profile.loading;
  });
  var updatingProfileLoading = useStoreState(function (state) {
    return state.profile.updatingProfileLoading;
  });
  var uploadingProfileAvatarLoading = useStoreState(function (state) {
    return state.profile.uploadingProfileAvatarLoading;
  });
  var isCookieAccepted = useStoreState(function (state) {
    return state.profile.isCookieAccepted;
  });
  var setIsCookieAccepted = useStoreActions(function (actions) {
    return actions.profile.setIsCookieAccepted;
  });
  var logOut = useCallback(function () {
    authLogout();
  }, [authLogout]); // ssr fix for everything user/auth related
  // see https://joshwcomeau.com/react/the-perils-of-rehydration/

  var _useState = useState(false),
      hasMounted = _useState[0],
      setHasMounted = _useState[1];

  useMount(function () {
    ensureProfileLoaded();
    setHasMounted(true);
  });
  return {
    accessToken: accessToken,
    profile: profile,
    isLoggedIn: isLoggedIn,
    logOut: logOut,
    isProfileCompleted: isProfileCompleted,
    isFullyActivated: isFullyActivated,
    canPurchaseArtwork: canPurchaseArtwork,
    isEmailVerified: isEmailVerified,
    emailVerificationRequired: emailVerificationRequired,
    updateProfileForm: updateProfileForm,
    saveProfile: saveProfile,
    uploadProfileAvatar: uploadProfileAvatar,
    uploadProfileAvatarLoading: uploadingProfileAvatarLoading,
    saveProfileLoading: !!profile && (updatingProfileLoading || profileLoading),
    profileIsLoading: hasMounted ? profileLoading || isAuthLoading : true,
    resendEmailVerification: resendEmailVerification,
    canResendEmailVerification: canResendEmailVerification,
    isCookieAccepted: isCookieAccepted,
    setIsCookieAccepted: setIsCookieAccepted,
    auth0UserFetched: auth0UserFetched
  };
}

export { useProfile };