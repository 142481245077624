import { useStaticQuery } from 'gatsby';
import React from 'react';
import { Socials } from './socials';
import { jsx as ___EmotionJSX } from "@emotion/core";
var pageQuery = "1541812469";

var MainSocials = function MainSocials(_ref) {
  var _data$cms$socialMedia, _data$cms$socialMedia2, _data$cms$socialMedia3;

  var style = _ref.style,
      className = _ref.className;
  var data = useStaticQuery(pageQuery);
  var socials = (_data$cms$socialMedia = data.cms.socialMediaGlobalSet) === null || _data$cms$socialMedia === void 0 ? void 0 : (_data$cms$socialMedia2 = _data$cms$socialMedia.data) === null || _data$cms$socialMedia2 === void 0 ? void 0 : (_data$cms$socialMedia3 = _data$cms$socialMedia2.attributes) === null || _data$cms$socialMedia3 === void 0 ? void 0 : _data$cms$socialMedia3.socialMediaLinks;
  return socials ? ___EmotionJSX(Socials, {
    style: style,
    className: className,
    socials: socials
  }) : null;
};

export { MainSocials };